@mixin flexible($width) {
  display: flex;
  width: $width;
}

@mixin gridable($width) {
  display: grid;
  width: $width;
}

@mixin unmarkedList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@mixin defaultLink {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: ease-in-out;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -3px;
    display: block;
    width: 0;
    height: 2px;
    background-color: $color-dark;
    transition: width 0.5s;
  }
  &:hover::after {
    @media #{$mouse-device} {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: $color-decorate-link;
      transition: width 0.5s;
    }
  }
}

@mixin defaultTitle {
  margin: 0;
  font-weight: 700;
  line-height: 1;
}

@mixin defaultSubtitle($font-size) {
  margin: 0;
  font-weight: 500;
  font-size: $font-size;
  line-height: 1;
}

@mixin button() {
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  background-color: transparent;
  border: none;
  border: none;
  transform: scale(1);
  cursor: pointer;
  transition: 0.5s;
}

@mixin darkTheme() {
  color: $color-light;
  background-color: $color-dark;
}
