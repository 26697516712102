@import '../../scss/mixins';
@import '../../scss/variables';

.header {
  @include gridable(100%);
  z-index: 15;
  grid-area: block;
  justify-items: center;
  box-sizing: border-box;
  height: $header-height;
  opacity: 1;
  transition: 0.5s ease-in-out;
  &_fixed {
    position: fixed;
    height: $header-height-small;
    background-color: rgba($color-dark, 0.7);
    backdrop-filter: blur(5px);
  }
  &__container {
    @include gridable(100%);
    grid-template-areas: 'logo-link nav theme';
    grid-template-columns: 1fr repeat(2, min-content);
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    max-width: $max-width;
    padding: 10px 20px;
    gap: 20px;
    @media screen and (max-width: $tablet-small) {
      grid-template-areas: 'logo-link theme nav';
    }
  }
  &__link {
    z-index: 10;
    grid-area: logo-link;
    width: max-content;
    cursor: pointer;
    opacity: 1;
    transition: 0.5s ease-in-out;
    &:hover {
      @media #{$mouse-device} {
        opacity: 0.5;
      }
    }
  }
  &__button {
    display: none;
    justify-items: right;
    @media screen and (max-width: $tablet-small) {
      @include flexible(32px);
      @include button();
      position: relative;
      z-index: 20;
      height: 32px;
      padding: 0;
      background-color: transparent;
      background-size: contain;
      border: none;
      border-radius: 0;
    }
  }
}
