@import '../../scss/mixins';
@import '../../scss/variables';

.footer {
  @include gridable(100%);
  grid-area: footer;
  justify-content: center;
  &__container {
    @include flexible(100%);
    align-items: center;
    box-sizing: border-box;
    max-width: $max-width;
    padding: 20px 50px;
    gap: 15px;
  }
  &__copyright {
    font-size: 16px;
    white-space: nowrap;
  }
}
