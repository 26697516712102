@import '../../scss/_mixins';
@import '../../scss/_variables';

.icon {
  &__project-link {
    width: 15px;
    height: 15px;
    transition: 0.5s ease-in-out;
    &_theme-dark {
      fill: $color-light;
    }
  }
  &__profile {
    width: 25px;
    height: 25px;
    animation: moveArrowDown 2.5s infinite ease-in-out;
    fill: $color-light;
  }
  &__skill {
    width: 120px;
    height: 120px;

    &_jquery {
      stroke: $color-light;
      stroke-width: 5;
    }
  }
  &__contact {
    width: 48px;
    height: 48px;
    transition: 0.5s ease-in-out;
    fill: $color-dark;
    &_theme-dark {
      fill: $color-light;
    }
  }
  &__logo {
    @include flexible(48px);
    z-index: 10;
    height: 48px;
    fill: $color-light;
    stroke: $color-dark;
    &_small {
      width: 24px;
      height: 24px;
    }
  }
  &__theme {
    z-index: 10;
    width: 24px;
    height: 24px;
    fill: $color-light;
  }
}

@keyframes moveArrowDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(7px);
  }
  100% {
    transform: translateY(0px);
  }
}
