@import '../../scss/mixins';
@import '../../scss/variables';

.app {
  @include gridable(100%);
  grid-template-areas:
    'block'
    'footer';
  grid-template-columns: 1fr;
  justify-items: center;
  align-content: space-between;
  box-sizing: border-box;
  height: 100%;
  font-weight: 400;
  line-height: 1.21;
  font-family: 'Inter', Arial, sans-serif;
  color: $color-dark;
  font-style: normal;
  background-color: $color-light;
  transition: 0.5s ease-in-out;
  &_theme-dark {
    @include darkTheme;
  }
}

html {
  scroll-behavior: smooth;
}
