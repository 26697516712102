$color-light: #ffffff;
$color-dark: #111111;
$color-bg-dot: #d8d8d8;
$color-decorate-link: #ff0000;
$header-height: 70px;
$header-height-small: 50px;
$max-width: 1280px;

$desktop: 1024px;
$tablet-large: 900px;
$tablet: 768px;
$tablet-small: 640px;
$smartphone: 480px;

$mouse-device: '(hover: hover) and (pointer: fine)';
