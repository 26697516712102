@import '../../scss/_mixins';
@import '../../scss/_variables';

.section {
  @include gridable(100%);
  justify-items: center;
  box-sizing: border-box;
  max-width: $max-width;
  padding-top: 75px;
  padding-right: 20px;
  padding-bottom: 0;
  padding-left: 20px;
  gap: 40px;
}
