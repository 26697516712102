@import '../../scss/mixins';
@import '../../scss/variables';

.contact {
  @include flexible(150px);
  justify-items: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  &__title {
    @include defaultSubtitle(18px);
  }
  &__link {
    @include defaultLink;
    color: $color-dark;
    transition: 0.5s ease-in-out;
    &_theme-dark {
      color: $color-light;
    }
  }
}
