@import '../../scss/mixins';
@import '../../scss/variables';

.contacts {
  padding-bottom: 70px;
  &__list {
    @include flexible(100%);
    @include unmarkedList;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px 70px;
  }
  &__title {
    @include defaultSubtitle(32px);
  }
}
