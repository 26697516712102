@import '../../scss/mixins';
@import '../../scss/variables';

.skill {
  @include gridable(100%);
  justify-items: center;
  max-width: 150px;
  gap: 20px;
  &__title {
    @include defaultSubtitle(18px);
    text-align: center;
  }
}
