@import '../../scss/mixins';
@import '../../scss/variables';

.header__menu {
  @include flexible(100%);
  @include unmarkedList;
  z-index: 10;
  font-size: 16px;
  gap: 15px;
  @media screen and (max-width: $tablet-small) {
    position: fixed;
    top: 0;
    right: -320px;
    z-index: 10;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 320px;
    height: 100vh;
    padding: $header-height 0;
    font-size: 18px;
    background-color: $color-dark;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    &_opened {
      right: 0;
      z-index: 15;
      visibility: visible;
      opacity: 1;
      transition: 0.5s;
    }
  }
  &-item {
    white-space: nowrap;
  }
  &-link {
    @include defaultLink;
    color: $color-light;
  }
}

.overlay {
  display: none;
  @media screen and (max-width: $tablet-large) {
    position: fixed;
    top: 0;
    right: -150%;
    z-index: 15;
    display: block;
    width: 100%;
    height: 100vh;
    background: $color-dark;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    &_active {
      left: 0;
      visibility: visible;
      cursor: pointer;
      opacity: 0.6;
      transition: 0.5s;
    }
  }
}

.cross {
  @include gridable(100%);
  align-content: space-between;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  &::before,
  &::after,
  & &__line {
    content: '';
    display: block;
    height: 2px;
    background: $color-light;
    border-radius: 2px;
    transition: 0.5s;
  }
  &_active {
    &::before {
      transform: translateY(10px) rotate(135deg);
    }
    &::after {
      transform: translateY(-9.5px) rotate(-135deg);
    }
    & .cross__line {
      transform: scale(0);
    }
  }
}
