@import '../../scss/mixins';
@import '../../scss/variables';

.skills {
  &__container {
    @include gridable(100%);
    justify-items: center;
    gap: 25px;
  }
  &__list {
    @include flexible(100%);
    @include unmarkedList;
    justify-content: center;
    justify-items: center;
    flex-wrap: wrap;
    gap: 25px 70px;
  }
  &__subtitle {
    @include defaultSubtitle(22px);
    text-align: center;
  }
}
