@import '../../scss/mixins';
@import '../../scss/variables';

.profile {
  align-content: center;
  max-width: 100%;
  height: 100vh;
  padding: 0;
  background-image: url('../../images/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  gap: 20px;
  &::before {
    content: '';
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    backdrop-filter: blur(25px);
  }
  &__info {
    @include gridable(100%);
    z-index: 10;
    justify-items: center;
    gap: 5px;
  }
  &__title {
    align-self: end;
    margin: 0;
    font-weight: 400;
    font-size: 80px;
    line-height: 1;
    color: $color-light;
    @media screen and (max-width: $tablet-small) {
      font-size: 60px;
    }
    @media screen and (max-width: $smartphone) {
      font-size: 45px;
    }
  }
  &__subtitle {
    margin: 0;
    font-weight: 500;
    font-size: 27px;
    line-height: 1;
    color: $color-light;
    @media screen and (max-width: $tablet-small) {
      font-size: 21px;
    }
    @media screen and (max-width: $smartphone) {
      font-size: 15px;
    }
  }
  &__text {
    z-index: 10;
    box-sizing: border-box;
    width: 100%;
    max-width: 600px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 16px;
    color: $color-light;
    @media screen and (max-width: $smartphone) {
      font-size: 14px;
    }
  }

  &__paragraph {
    margin: 0;
    text-align: center;
  }
  &__link {
    @include flexible(100%);
    position: absolute;
    bottom: 15px;
    z-index: 10;
    justify-content: center;
    align-items: center;
    align-self: end;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 2px solid $color-light;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    &:hover {
      @media #{$mouse-device} {
        background-color: rgba($color-dark, 0.4);
      }
    }
  }
}
