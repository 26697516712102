@import '../../scss/mixins';
@import '../../scss/variables';

.header {
  &__switcher {
    z-index: 10;
    grid-area: theme;
    &-button {
      @include button;
      @include flexible(100%);
      align-items: center;
      opacity: 1;
      transition: 0.5s ease-in-out;
      &:hover {
        @media #{$mouse-device} {
          opacity: 0.8;
        }
      }
    }
  }
}
